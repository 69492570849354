import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import {
  Box,
  Avatar,
  MenuItem,
  Menu,
  IconButton,
  Toolbar,
  AppBar,
  Skeleton,
  Stack,
  Divider,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import breakpoints from "assets/theme/base/breakpoints";
import Breadcrumbs from "Container/Breadcrumbs";
import styles from "Container/DashboardNavbar/styles";
import { stringAvatar, capitalize } from "api/helper";
import { updateUI } from "app/features/UIControllerSlice";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import SuiTypography from "components/SuiTypography";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: user, isLoading } = useHandleGetApiQuery({ url: `insurer/profile` });
  const controller = useSelector((store) => store.uiControl);
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1);
  let name = "PolEasy Insurer";
  let email = "admin@polEasy.com";
  let profileImage;
  let company;
  if (user && !isLoading) {
    localStorage.setItem("user", JSON.stringify(user?.data));
    profileImage = user.data.profile_image;
    name = user.data.name;
    email = user.data.email;
    company = user.data.policy_provider;
  }
  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      dispatch({
        type: "transparentNavbar",
        value: (fixedNavbar && window.scrollY !== 0) || !fixedNavbar,
      });
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const displayMobileNavbar = () => {
      if (window.innerWidth < breakpoints.values.sm) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    window.addEventListener("resize", displayMobileNavbar);

    displayMobileNavbar();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);
  useEffect(() => {
    if (user) {
      if (user.data.adm_is_deleted) {
        navigate("/log-out");
      }
    }
  }, [user]);
  const handleMiniSidenav = () => dispatch(updateUI({ type: "miniSidenav", value: !miniSidenav }));
  const data = isLoading;
  const accountMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        {data ? (
          <>
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
            {!mobileView ? <Skeleton height={30} width={80} sx={{ marginLeft: "10px" }} /> : null}
          </>
        ) : (
          <Box
            className={`${classes.avatar_container} cursor-pointer`}
            onClick={handleClick}
            title={capitalize(name)}
          >
            <IconButton
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              title={name}
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar src={profileImage} alt={name} {...stringAvatar(name, profileImage)} />
            </IconButton>
            {!mobileView ? (
              <Box
                component="img"
                src={company?.logo}
                alt={company?.company_name}
                width={100}
                height={40}
                sx={{
                  width: "100%",
                  maxWidth: 100,
                  maxHeight: 40,
                  objectFit: "contain",
                }}
              />
            ) : null}
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          disableScrollLock={true}
        >
          <Box component="li">
            {mobileView ? (
              <Stack direction="row" spacing={1.2}>
                <Avatar
                  src={profileImage}
                  alt={name}
                  {...stringAvatar(name, profileImage, "40px")}
                />
                <Box
                  component="img"
                  src={company?.logo}
                  alt={company?.company_name}
                  width={100}
                  height={40}
                  sx={{
                    width: "100%",
                    maxWidth: 100,
                    maxHeight: 40,
                    objectFit: "contain",
                  }}
                />
              </Stack>
            ) : null}
            <SuiTypography fontSize={16} pt={1} textColor="text2" fontWeight="semiBold">
              {capitalize(name)}
            </SuiTypography>
            <SuiTypography fontSize={15} textColor="text2" lineHeight={1}>
              {email.toLocaleLowerCase()}
            </SuiTypography>
          </Box>
          <Divider sx={{ mb: 0.5 }} />
          <MenuItem
            sx={{
              color: "#FC3C3C",
              "&:hover": { bgcolor: "#FC3C3C", color: "#fff" },
            }}
            component={Link}
            to="/log-out"
          >
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <Toolbar className={classes.navbar_container}>
        <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
          <Breadcrumbs
            icon="home"
            title={route[route.length - (route.length < 3 ? 1 : 2)]}
            route={route}
            light={light}
          />
        </SuiBox>
        {isMini ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <IconButton
                size="medium"
                color="inherit"
                className={classes.navbar_mobile_menu}
                onClick={handleMiniSidenav}
              >
                <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              {accountMenu()}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
