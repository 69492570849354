import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

const { inputColors } = colors;
const { borderWidth, borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      display: "flex !important",
      padding: 0,
      "& fieldset": {
        border: "none",
      },
    },

    input: {
      height: pxToRem(25),
      padding: `${pxToRem(8)} ${pxToRem(12)}`,
      border: `${borderWidth[2]} solid ${inputColors.borderColor.main}`,
      "&:focus": {
        border: `${borderWidth[2]} solid ${inputColors.borderColor.focus}`,
      },
      borderRadius: `${borderRadius.lg}`,
      "&:disabled": {
        backgroundColor: "#E5EBED",
        opacity: "0.7",
      },
      "&:-webkit-autofill": {
        borderRadius: `${borderRadius.lg}`,
      },
    },

    inputSizeSmall: {
      height: pxToRem(22),
    },
    inputSizeLarge: {
      height: pxToRem(40),
    },
  },
};
