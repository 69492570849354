import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;

export default {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid #D3E0E5`,
      color: "#303841",
      fontWeight: "500",
    },
  },
};
