import { Toast } from "components/Toaster";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { setCredentials } from "app/features/AuthSlice";
import { useDispatch } from "react-redux";
import SuiBox from "components/SuiBox";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
const PoleaseLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("user");
  const auth_token = searchParams.get("auth");
  localStorage.removeItem("tokenExpiryTime");
  localStorage.removeItem("token");
  localStorage.removeItem("loggedInTime");
  localStorage.removeItem("timer");
  localStorage.removeItem("user");
  const handlePostRequest = async ({ url, data, token }) => {
    const headers = {
      authorization: `Bearer ${token}`,
    };

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}${url}`, data, {
        headers: headers,
      })
      .then((response) => {
        localStorage.setItem("token", response?.data.data.access_token);
        localStorage.setItem("refresh_token", response?.data.data.refresh_token);
        localStorage.setItem("tokenExpiryTime", response?.data.data.access_token_expiry);
        localStorage.setItem("loggedInTime", new Date());
        dispatch(
          setCredentials({ token: response?.data.data.access_token, isAuthenticated: true })
        );
        navigate("/dashboard");
      })
      .catch((error) => {
        if (error?.response?.data?.status?.code === 403) {
          Toast({ type: "error", message: error?.response.data.status.message });
        } else {
          Toast({ type: "error", message: error?.message });
        }
        navigate("/");
      });
  };
  useEffect(() => {
    if (auth_token) {
      const data = { email };
      handlePostRequest({
        url: "insurer/poleasy-login",
        data,
        token: auth_token,
      });
    } else {
      navigate("/");
    }
  }, [auth_token, email]);
  return (
    <SuiBox display="flex" justifyContent="center" alignItems="center">
      <CommonSpinner size="100px" color={colors.info.main} />
    </SuiBox>
  );
};

export default PoleaseLogin;
