import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isAuthenticated: localStorage.getItem("token"),
  otp: "",
  resendOtp: false,
  mobile: "",
  updateMobile: null,
  updateMobileCountryCode: null,
  countryCode: "+91",
  token: localStorage.getItem("token"),
  isVerified: false,
  openSessionModal: false,
  id: null,
  user: null,
  searchParams: {},
  permissions: null,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    Logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem("tokenExpiryTime");
      localStorage.removeItem("token");
      localStorage.removeItem("loggedInTime");
      localStorage.removeItem("refresh_token");
    },
    getCountryCode: (state, action) => {
      const data = action.payload;
      state.countryCode = data;
    },
    getSorting: (state, action) => {
      const data = action.payload;
      state.searchParams = { ...state.searchParams, ordering: `${data.order}${data.orderBy}` };
    },
    getPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setSearchParams: (state, action) => {
      const data = action.payload;
      state.searchParams = data;
    },
    getId: (state, action) => {
      const data = action.payload;
      state.id = data;
    },
    getMobile: (state, action) => {
      const data = action.payload;
      state.mobile = data;
    },
    resetOtp: (state, action) => {
      const data = action.payload;
      state.otp = data;
    },
    resendOtp: (state, action) => {
      const data = action.payload;
      state.resendOtp = data;
    },
    handleUpdateProfile: (state, action) => {
      const data = action.payload;
      state.updateMobile = data.mobile;
      state.updateMobileCountryCode = data.countryCode;
    },
    handleSessionModal: (state, action) => {
      const data = action.payload;
      state.openSessionModal = data;
    },
  },
});

export const {
  getCountryCode,
  getMobile,
  resetOtp,
  resendOtp,
  handleUpdateProfile,
  handleSessionModal,
  setCredentials,
  Logout,
  getId,
  getSorting,
  setSearchParams,
  getPermissions,
} = authSlice.actions;

export default authSlice.reducer;
