import { useRef, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import gradientChartLine from "assets/theme/functions/gradientChartLine";
import configs from "Container/Charts/LineCharts/GradientLineChart/configs";
import colors from "assets/theme/base/colors";
import { Box, Grid } from "@mui/material";
import CheckboxLegend from "Container/ChartLegend/CheckboxLegend";

function GradientLineChart({ title, height, chart, showTotalCount }) {
  const chartRef = useRef(null);
  const myChartRef = useRef();
  const [chartData, setChartData] = useState({});

  const { data, options } = chartData;
  useEffect(() => {
    const chartDatasets = chart.datasets.map((dataset) => ({
      ...dataset,
      tension: 0.4,
      pointRadius: 0,
      borderWidth: dataset.borderWidth,
      borderColor: colors[dataset.color].main,
      fill: true,
      maxBarThickness: 1,
      backgroundColor:
        dataset.bgcolor || gradientChartLine(chartRef.current.children[0], colors.primary.main),
    }));

    setChartData(configs(chart.labels, chartDatasets, chart.xTitle));
  }, [chart]);

  const toggleLegend = (e, index) => {
    const myChart = myChartRef.current;
    if (myChart.isDatasetVisible(index) && !e.target.checked) {
      myChart.hide(index);
    } else {
      myChart.show(index);
    }
  };

  const toolTipLine = {
    id: "toolTipLine",
    beforeDraw(chart) {
      const { ctx, tooltip, chartArea } = chart;

      if (tooltip._active && tooltip._active.length) {
        ctx.save();

        const activePoint = tooltip._active[0];

        ctx.beginPath();
        ctx.setLineDash([3, 4]);
        ctx.moveTo(activePoint.element.x, chartArea.top);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#231F20";
        ctx.stroke();
        ctx.restore();

        ctx.beginPath();
        ctx.setLineDash([3, 4]);
        ctx.moveTo(activePoint.element.x, chartArea.bottom);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#231F20";
        ctx.stroke();
        ctx.restore();
      }
    },
  };
  const totalCount = chart?.datasets.map((item) =>
    item?.data?.reduce((totalVal, num) => totalVal + num, 0)
  );
  const renderChart = (
    <SuiBox p={2}>
      {title ? (
        <Grid
          container
          pl={{ xs: 1, md: 0 }}
          alignItems="center"
          columnSpacing={1.5}
          rowSpacing={1}
          mb={2}
        >
          <Grid item xs={12} sm="auto">
            <SuiTypography variant="h6" fontSize={14}>
              {title}
            </SuiTypography>
          </Grid>
          {showTotalCount ? (
            <Grid item xs={12} sm="auto">
              <SuiTypography textColor="text2" fontSize={13}>
                Total Count:{" "}
                <SuiTypography component="span" fontWeight="bold" fontSize={14}>
                  {totalCount[0]}
                </SuiTypography>
              </SuiTypography>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Grid
          container
          justifyContent={{ xs: "flex-start", md: "center" }}
          pl={{ xs: 1, md: 0 }}
          alignItems="center"
          columnSpacing={2}
          rowSpacing={1}
          mb={2}
        >
          {chart?.datasets.map((item, index) => (
            <Grid item key={index}>
              <CheckboxLegend
                id={index + item.label.split(" ").join()}
                color={item.color}
                label={`${item.label} (${totalCount[index]})`}
                onLegendFn={(e) => toggleLegend(e, index)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {useMemo(
        () => (
          <Box ref={chartRef} height={height}>
            <Line ref={myChartRef} data={data} options={options} plugins={[toolTipLine]} />
          </Box>
        ),
        [chartData, height]
      )}
    </SuiBox>
  );

  return <Card sx={{ "& > .MuiBox-root": { p: { xs: 1, md: 2 } } }}>{renderChart}</Card>;
}

GradientLineChart.defaultProps = {
  title: "",
  height: "19.125rem",
};

GradientLineChart.propTypes = {
  title: PropTypes.string,
};

export default GradientLineChart;
