import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const { white } = colors;

export default {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: white,
      minHeight: "unset",
      padding: pxToRem(0),
    },

    flexContainer: {
      height: "100%",
      gap: 10,
    },

    indicator: {
      height: "0",
    },
  },
};
