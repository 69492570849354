import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Grid } from "@mui/material";
import SuiButton from "components/SuiButton";

const BootstrapDialogTitle = (props) => {
  const { onClose } = props;

  return (
    <>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: -9,
            top: -9,
            p: 0,
            bgcolor: "#447beb !important",
          }}
        >
          <CloseIcon sx={{ width: 16, height: 16, fill: "#fff", p: "1px" }} />
        </IconButton>
      ) : null}
    </>
  );
};
function SuiDialog({ title, subTitle, width, handleClose, onSubmit, open, isEdit }) {
  return (
    <Dialog
      open={open}
      sx={{ "& .MuiPaper-root": { borderRadius: 2, overflow: "initial", minWidth: width } }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
      <DialogContent
        sx={{ padding: "1rem 1rem 0.65rem !important", minWidth: 340, maxWidth: width }}
      >
        <SuiBox component="form">
          {title || isEdit?.title ? (
            <SuiTypography
              component="h2"
              textAlign="center"
              textColor="dark"
              fontWeight="semiBold"
              fontSize={{ xs: 14, md: 16 }}
              lineHeight={1.2}
              pt={2}
            >
              {isEdit
                ? `Are you sure you want to ${isEdit?.udatingState} this ${isEdit?.title}?`
                : title}
            </SuiTypography>
          ) : null}
          {subTitle || isEdit?.subTitle ? (
            <SuiTypography
              component="p"
              textAlign="center"
              lineHeight={1.2}
              fontSize={{ xs: 13, md: 15 }}
              textColor="dark"
              my={1}
            >
              {isEdit ? isEdit?.subTitle : subTitle}
            </SuiTypography>
          ) : null}
          <Grid container spacing={1} alignItems="center" justifyContent="center" py={2}>
            <Grid item>
              <SuiButton
                size="small"
                sx={{ fontSize: "0.9rem", p: "0.4rem 2rem", fontWeight: "500" }}
                variant="outlined"
                buttonColor="dark"
                onClick={handleClose}
              >
                {isEdit ? `Keep ${isEdit?.currentState}` : "Don’t Delete"}
              </SuiButton>
            </Grid>
            <Grid item>
              <SuiButton
                size="small"
                sx={{ fontSize: "0.9rem", fontWeight: "500" }}
                buttonColor={isEdit ? isEdit?.buttonColor : "error"}
                onClick={onSubmit}
              >
                {isEdit ? `Yes, ${isEdit?.udatingState}` : "Yes, Delete"}
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </DialogContent>
    </Dialog>
  );
}

BootstrapDialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
};
SuiDialog.propTypes = {
  width: PropTypes.any,
  handleClose: PropTypes.func,
  children: PropTypes.node,
};

export default SuiDialog;
