import React from "react";
import PropTypes from "prop-types";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SuiTypography from "components/SuiTypography";

const SuiSearchBox = ({ title, children }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ width: "1.5rem", height: "1.5rem" }} />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        sx={{ px: { xs: 1, md: 1.5 } }}
      >
        <SuiTypography fontWeight="bold">{title}</SuiTypography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: { xs: 0.5, md: 1.5 } }}>{children}</AccordionDetails>
    </Accordion>
  );
};

SuiSearchBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default SuiSearchBox;
