import React from "react";
import PropTypes from "prop-types";

import { FormLabel } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";

const SuiDatePicker = ({ input }) => {
  const [focus, setFocus] = React.useState(false);
  return (
    <>
      {input.lable && (
        <FormLabel htmlFor={input.id} required={!input.notRequired}>
          {input.lable}
        </FormLabel>
      )}
      <SuiBox customClass={`input ${focus && "focus"}`} pr={2.1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={input.disabled}
            readOnly={input.readOnly}
            inputProps={{
              disabled: input.disabled,
              style: { border: "none", borderRadius: 0 },
              required: !input.notRequired,
              readOnly: input.readOnly,
              placeholder: input.placeholder ? input.placeholder : "DD/MM/YYYY",
            }}
            inputFormat="DD/MM/YYYY"
            minDate={input.minDate}
            maxDate={new Date()}
            value={input.value}
            onChange={input.fn}
            name={input.id}
            renderInput={(params) => (
              <SuiInput
                id={input.id}
                {...params}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
            )}
          />
        </LocalizationProvider>
      </SuiBox>
    </>
  );
};

SuiDatePicker.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
    lable: PropTypes.string,
    minDate: PropTypes.any,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    fn: PropTypes.func,
    notRequired: PropTypes.bool,
  }),
};

export default SuiDatePicker;
