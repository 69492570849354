import React from "react";
import { Link } from "react-router-dom";
import { Box, Card, Divider, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import withPostContainer from "Container/withPostContainer";
import { capitalize } from "api/helper";
import moment from "moment";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";

const ValueLabel = ({ label, value, fontStyle, color }) => {
  let statusColor;
  switch (value) {
    case "Open":
      statusColor = "info";
      break;
    case "In Progress":
      statusColor = "warning";
      break;
    case "Closed":
      statusColor = "success";
      break;
    case "Rejected":
      statusColor = "error";
      break;
    default:
      statusColor = "inherit";
  }
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      columnSpacing={1}
      rowSpacing={0.5}
    >
      <Grid item flexShrink={0}>
        <SuiTypography textColor="text2" fontWeight="medium" fontSize="0.9rem">
          {label}
        </SuiTypography>
      </Grid>
      <Grid item>
        <SuiTypography
          fontStyle={fontStyle}
          textColor={color || statusColor}
          fontWeight="medium"
          fontSize="0.9rem"
        >
          {value}
        </SuiTypography>
      </Grid>
    </Grid>
  );
};
const GridValueLabel = ({ label, value, color }) => {
  const box_1_Style = { gridArea: "1 / 1 / 2 / 2" };
  const box_2_Style = { gridArea: { xs: "2 / 1 / 3 / 2", md: "1 / 2 / 2 / 3" } };
  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: { xs: "1fr", md: "repeat(2, 50%)" },
        gridTemplateRows: { xs: "repeat(2, 1fr)", md: "1fr" },
        gridGap: { xs: 6, md: 16 },
      }}
    >
      <Box sx={box_1_Style}>
        <SuiTypography textColor="text2" fontWeight="medium" fontSize="0.9rem">
          {label}
        </SuiTypography>
      </Box>
      <Box sx={box_2_Style}>
        <SuiTypography textColor={color || "inherit"} fontWeight="medium" fontSize="0.9rem">
          {value}
        </SuiTypography>
      </Box>
    </Box>
  );
};
const LeadManagementView = ({ data }) => {
  return (
    <SuiBox py={3}>
      <Card sx={{ px: 2, py: 3 }}>
        <SuiTypography component="h2" fontWeight="semiBold" fontSize="1.2rem" pb={3}>
          Details
        </SuiTypography>
        <Grid container>
          {!data && (
            <Grid item xs={12}>
              <SuiBox display="flex" height="250px" alignItems="center" justifyContent="center">
                <CommonSpinner size="100px" color={colors.info.main} />
              </SuiBox>
            </Grid>
          )}
          <Grid item xs={12} xxl={8}>
            <Grid container columnSpacing={1} rowSpacing={3}>
              {data ? (
                <>
                  <Grid item xs={12}>
                    <ValueLabel
                      label="Agent Name:"
                      value={capitalize(
                        `${data?.data.agent.title} ${data?.data.agent.first_name} ${
                          data?.data.agent?.middle_name ? data?.data.agent?.middle_name : ""
                        } ${data?.data.agent?.last_name}`
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <ValueLabel
                      label="Contact request accepted:"
                      value={data?.data.contact_request_accepted ? "Yes" : "No"}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <ValueLabel
                      label="Lead recieved on:"
                      value={moment(new Date(data?.data?.created_at)).format(
                        "Do MMM YYYY | hh:mm A"
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <ValueLabel
                      label="Customer Name:"
                      value={capitalize(
                        `${data?.data.user.usr_title} ${data?.data.user.usr_first_name} ${
                          data?.data.user?.usr_middle_name ? data?.data.user?.usr_middle_name : ""
                        } ${data?.data.user?.usr_last_name}`
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ValueLabel label="Status:" value={capitalize(data?.data?.status)} />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <ValueLabel
                      label="Is contact shared:"
                      value={data?.data.is_contact_shared ? "Yes" : "No"}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <ValueLabel
                      label="Is portfolio shared:"
                      value={data?.data.is_portfolio_shared ? "Yes" : "No"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ValueLabel
                      label="Lead closed on:"
                      value={
                        data?.data?.lead_closed_on
                          ? moment(new Date(data?.data?.lead_closed_on)).format(
                              "Do MMM YYYY | hh:mm A"
                            )
                          : "N/A"
                      }
                    />
                  </Grid>
                  {data?.data.user_questionnaires_response &&
                  data?.data.user_questionnaires_response.length ? (
                    <Grid item xs={12}>
                      <Divider />
                      <SuiTypography
                        component="h2"
                        textColor="dark"
                        fontWeight="semiBold"
                        fontSize={17}
                        lineHeight={1.2}
                      >
                        Customer response for profile sharing :-
                      </SuiTypography>
                      <Grid container spacing={1.5} pt={2} pl={2}>
                        {data?.data.user_questionnaires_response?.map(
                          ({ question_id, question, answer }) => {
                            const isDate = moment(new Date(answer)).isValid();
                            const haveAnswer = answer
                              ? isDate
                                ? moment(new Date(answer)).format("DD / MM / YYYY")
                                : answer
                              : "N/A";

                            return (
                              <Grid item xs={12} key={question_id}>
                                <GridValueLabel
                                  color="warning"
                                  label={`${question} :`}
                                  value={haveAnswer}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                  {data?.data.agent_questionnaires_response &&
                  data?.data.agent_questionnaires_response.length ? (
                    <Grid item xs={12}>
                      <Divider />
                      <SuiTypography
                        component="h2"
                        textColor="dark"
                        fontWeight="semiBold"
                        fontSize={17}
                        lineHeight={1.2}
                      >
                        Agent response at the time of closing lead :-
                      </SuiTypography>
                      <Grid container spacing={1.5} pt={2} pl={2}>
                        {data?.data.agent_questionnaires_response?.map(
                          ({ question_id, question, answer }) => {
                            const isDate = moment(new Date(answer)).isValid();
                            const haveAnswer = answer
                              ? isDate
                                ? moment(new Date(answer)).format("DD / MM / YYYY")
                                : answer
                              : "N/A";

                            return (
                              <Grid item xs={12} key={question_id}>
                                <GridValueLabel
                                  color="warning"
                                  label={`${question} :`}
                                  value={haveAnswer}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              ) : null}

              <Grid item sx={{ mt: 7 }}>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/lead-generation-reports"
                  sx={{ fontSize: "1rem", fontWeight: "500", px: 7 }}
                  buttonColor="info"
                >
                  Back
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </SuiBox>
  );
};

export default withPostContainer(LeadManagementView, "insurer/lead-view/");
