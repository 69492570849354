import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { TableCell, TableRow, Grid, FormLabel, IconButton, Select, MenuItem } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SuiBox from "components/SuiBox";
import SuiSearchBox from "components/SuiSearchBox";
import SuiTable from "components/SuiTable";
import SuiTablePagination from "components/SuiTablePagination";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { getNewProperties, capitalize, formatDate } from "api/helper";
import { useDispatch } from "react-redux";
import { setSearchParams } from "app/features/AuthSlice";
import withGetContainer from "Container/withGetContainer";
import colors from "assets/theme/base/colors";
import moment from "moment";
import SuiDatePicker from "components/SuiDatePicker";
import { getReports } from "api/helper";
import { Toast } from "components/Toaster";
import ErrorMessageComponent from "components/FormikErrorMessage";

const toolbar = {
  title: "Lead Generation Reports",
};
const headCells = [
  {
    id: "agent_name",
    width: "auto",
    align: "left",
    sorting: true,
    label: "AGENT NAME",
  },
  {
    id: "user_name",
    width: "auto",
    align: "left",
    sorting: false,
    label: "CUSTOMER NAME",
  },
  {
    id: "status",
    width: "auto",
    align: "left",
    sorting: true,
    label: "STATUS",
  },
  {
    id: "created_at",
    width: "auto",
    align: "left",
    sorting: true,
    label: "LEAD RECIEVED ON",
  },
  {
    id: "lead_closed_on",
    width: "auto",
    align: "left",
    sorting: true,
    label: "LEAD CLOSED ON",
  },
  {
    id: "ACTION",
    width: "auto",
    align: "right",
    sorting: false,
    label: "ACTION",
  },
];

const LeadManagementTable = ({
  data,
  isLoading,
  isFetching,
  permissions,
  resetShorting,
  setResetShorting,
  searchParams,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEdit = useCallback(
    (id) => () => {
      navigate(`${id}`);
    },
    []
  );
  const formik = useFormik({
    initialValues: {
      agent_name: "",
      page: 1,
      status: "",
      lead_received_from_date: null,
      lead_received_to_date: null,
      lead_closed_from_date: null,
      lead_closed_to_date: null,
    },
    validationSchema: yup.object({
      lead_received_from_date: yup
        .date()
        .max(new Date(), "Start date should not be greater than today.")
        .typeError("Please enter valid start date.")
        .nullable(),
      lead_received_to_date: yup
        .date()
        .max(new Date(), "End date should not be greater than today.")
        .when("lead_received_from_date", {
          is: (lead_received_from_date) => moment.isDate(lead_received_from_date),
          then: yup
            .date()
            .required("Please enter end date.")
            .typeError("Please enter valid end date.")
            .nullable(),
        })
        .test(
          "lessthenSD",
          "End date should not be less than start date.",
          (value, testContext) => {
            return !moment(value).isBefore(testContext.parent.lead_received_from_date);
          }
        )
        .typeError("Please enter valid end date.")
        .nullable(),
      lead_closed_from_date: yup
        .date()
        .max(new Date(), "Start date should not be greater than today.")
        .typeError("Please enter valid start date.")
        .nullable(),
      lead_closed_to_date: yup
        .date()
        .max(new Date(), "End date should not be greater than today.")
        .when("lead_closed_from_date", {
          is: (lead_closed_from_date) => moment.isDate(lead_closed_from_date),
          then: yup
            .date()
            .required("Please enter end date.")
            .typeError("Please enter valid end date.")
            .nullable(),
        })
        .test(
          "lessthenSD",
          "End date should not be less than start date.",
          (value, testContext) => {
            return !moment(value).isBefore(testContext.parent.lead_closed_from_date);
          }
        )
        .typeError("Please enter valid end date.")
        .nullable(),
    }),
    onSubmit: (values, { setFieldValue }) => {
      const search = getNewProperties(formik.initialValues, values);
      dispatch(setSearchParams({ ...search, page: 1 }));
      setFieldValue("page", 1);
    },
  });
  const handleChange = (event, value) => {
    formik.setFieldValue("page", value);
    dispatch(setSearchParams({ ...searchParams, page: value }));
  };
  const handleStartDate = (e) => {
    if (e) {
      formik.setFieldValue("lead_received_from_date", formatDate(new Date(e)));
      formik.setFieldValue("lead_received_to_date", formatDate(new Date()));
    } else {
      formik.setFieldValue("lead_received_from_date", null);
      formik.setFieldValue("lead_received_to_date", null);
    }
  };
  const handleEndDate = (e) => {
    if (e) {
      formik.setFieldValue("lead_received_to_date", formatDate(new Date(e)));
    } else {
      formik.setFieldValue("lead_received_to_date", null);
      formik.setFieldValue("lead_received_from_date", null);
    }
  };
  const handleLeadStartDate = (e) => {
    if (e) {
      formik.setFieldValue("lead_closed_from_date", formatDate(new Date(e)));
      formik.setFieldValue("lead_closed_to_date", formatDate(new Date()));
    } else {
      formik.setFieldValue("lead_closed_from_date", null);
      formik.setFieldValue("lead_closed_to_date", null);
    }
  };
  const handleLeadEndDate = (e) => {
    if (e) {
      formik.setFieldValue("lead_closed_to_date", formatDate(new Date(e)));
    } else {
      formik.setFieldValue("lead_closed_to_date", null);
      formik.setFieldValue("lead_closed_from_date", null);
    }
  };
  const handleExport = async () => {
    const search = getNewProperties(formik.initialValues, {
      ...formik.values,
      page: formik.initialValues.page,
    });
    const response = await getReports(search, "insurer/leads-report-export");
    if (response?.data.status.code === 200) {
      Toast({ type: "success", message: response?.data.status.message });
      navigate("/downloads");
    }
  };
  const handleFormReset = useCallback(() => {
    formik.resetForm();
    setResetShorting(true);
    dispatch(setSearchParams({}));
  }, []);
  const rows = data?.data.results.map((row) => {
    return (
      <TableRow hover key={row.lead_id}>
        <TableCell component="th" scope="row">
          {capitalize(row?.agent_name)}
        </TableCell>
        <TableCell>{capitalize(row?.customer_name)}</TableCell>
        <TableCell
          sx={{
            color: row.status === "OPEN" ? colors.info.main : colors.success.main,
          }}
        >
          {capitalize(row.status)}
        </TableCell>
        <TableCell>{moment(new Date(row.created_at)).format("Do MMM YYYY | hh:mm A")}</TableCell>
        <TableCell>
          {row.lead_closed_on
            ? moment(new Date(row.lead_closed_on)).format("Do MMM YYYY | hh:mm A")
            : "--"}
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={handleEdit(row.lead_id)}>
            <VisibilityOutlinedIcon sx={{ fill: "#ED952F" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox component="form" mx={1}>
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormLabel htmlFor="agent_name">Agent name</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Agent name"
                  id="agent_name"
                  name="agent_name"
                  value={formik.values.agent_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel htmlFor="status">Status</FormLabel>
                <Select
                  name="status"
                  value={formik.values.status}
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  inputProps={{ id: "status" }}
                  onChange={formik.handleChange}
                  sx={{
                    "&> .MuiSelect-select": {
                      color: formik.values.status !== "" ? "inherit" : "#AAAEB1 !important",
                    },
                  }}
                >
                  <MenuItem value="">Select Status</MenuItem>
                  <MenuItem value="OPEN">OPEN</MenuItem>
                  <MenuItem value="CLOSED">CLOSED</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Lead recieved on</FormLabel>
                <Grid container spacing={2} flexWrap={{ xs: "wrap", md: "nowrap" }} pt={2}>
                  <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                    <SuiDatePicker
                      input={{
                        id: "lead_received_from_date",
                        lable: "",
                        placeholder: "From",
                        value: formik.values.lead_received_from_date,
                        fn: handleStartDate,
                      }}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.lead_received_from_date}
                      error={formik.errors.lead_received_from_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pt: { xs: 1, md: "0 !important" } }}>
                    <SuiDatePicker
                      input={{
                        id: "lead_received_to_date",
                        lable: "",
                        placeholder: "To",
                        disabled: !formik.values.lead_received_from_date ? true : false,
                        minDate: new Date(formik.values.lead_received_from_date),
                        value: formik.values.lead_received_to_date,
                        fn: handleEndDate,
                      }}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.lead_received_to_date}
                      error={formik.errors.lead_received_to_date}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Lead closed on</FormLabel>
                <Grid container spacing={2} flexWrap={{ xs: "wrap", md: "nowrap" }} pt={2}>
                  <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                    <SuiDatePicker
                      input={{
                        id: "lead_closed_from_date",
                        lable: "",
                        placeholder: "From",
                        value: formik.values.lead_closed_from_date,
                        fn: handleLeadStartDate,
                      }}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.lead_closed_from_date}
                      error={formik.errors.lead_closed_from_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pt: { xs: 1, md: "0 !important" } }}>
                    <SuiDatePicker
                      input={{
                        id: "lead_closed_to_date",
                        lable: "",
                        placeholder: "To",
                        disabled: !formik.values.lead_closed_from_date ? true : false,
                        minDate: new Date(formik.values.lead_closed_from_date),
                        value: formik.values.lead_closed_to_date,
                        fn: handleLeadEndDate,
                      }}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.lead_closed_to_date}
                      error={formik.errors.lead_closed_to_date}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SuiBox my={2}>
                  <SuiButton
                    type="button"
                    size="small"
                    buttonColor="info"
                    sx={{ px: "1rem", fontSize: "14px", marginRight: "14px" }}
                    onClick={(e) => {
                      setResetShorting(true);
                      formik.handleSubmit(e);
                    }}
                    disabled={!formik.dirty}
                  >
                    Search
                  </SuiButton>
                  <SuiButton
                    type="reset"
                    onClick={handleFormReset}
                    size="small"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem", fontSize: "14px" }}
                  >
                    Reset
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <SuiTable
          reset={resetShorting}
          setReset={setResetShorting}
          isLoading={isLoading}
          isFetching={isFetching}
          exportBtn={handleExport}
          tableMinWidth="970px"
          toolbar={toolbar}
          tablehead={headCells}
          rows={rows}
          permission={permissions?.create}
        />
      </SuiBox>
      <SuiTablePagination
        totalData={data?.data.count}
        totalShowing={data?.data.results.length}
        currentPage={{ page: formik.values.page, fn: handleChange }}
      />
    </>
  );
};
export default withGetContainer({
  WrappedComponent: LeadManagementTable,
  url: "insurer/leads-report?pagination=true",
});
