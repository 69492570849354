import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { baseAPIService } from "./features/api";
import AuthReducer from "./features/AuthSlice";
import UIControllerReducer from "./features/UIControllerSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    uiControl: UIControllerReducer,
    [baseAPIService.reducerPath]: baseAPIService.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(baseAPIService.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export default store;
