import React from "react";
import { Checkbox } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

const CheckboxLegend = ({ id, color, label, onLegendFn }) => {
  return (
    <SuiBox
      display="flex"
      gap={0.5}
      alignItems="center"
      sx={{
        userSelect: "none",
        "& > .MuiTypography-root": {
          textDecoration: "line-through",
          opacity: 0.7,
        },
        "& > .MuiCheckbox-root.Mui-checked + .MuiTypography-root": {
          textDecoration: "none",
          opacity: 1,
        },
      }}
    >
      <Checkbox
        color={color}
        size="small"
        id={`legend_${id}`}
        name={`legend_${id}`}
        defaultChecked
        onChange={onLegendFn}
      />
      <SuiTypography
        sx={{ cursor: "pointer" }}
        component="label"
        htmlFor={`legend_${id}`}
        fontWeight="medium"
        fontSize={14}
      >
        {label}
      </SuiTypography>
    </SuiBox>
  );
};

export default CheckboxLegend;
