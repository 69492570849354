import { baseAPIService } from ".";

const CommonChangeApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    handleUpdateOrDelete: build.mutation({
      query: ({ url, data, update }) => {
        return {
          url: url,
          method: update ? "PATCH" : "DELETE",
          body: data,
        };
      },
      invalidatesTags: ["COMMON_GET"],
    }),
  }),
  overrideExisting: true,
});
export const { useHandleUpdateOrDeleteMutation } = CommonChangeApi;
