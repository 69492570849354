import { baseAPIService } from ".";

const AuthApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    generateOtp: build.mutation({
      query: (data) => {
        return {
          url: "insurer/generate-otp",
          method: "POST",
          body: data,
        };
      },
    }),
    verifyOtp: build.mutation({
      query: (data) => {
        return {
          url: "insurer/login",
          method: "POST",
          body: data,
        };
      },
    }),
    logout: build.mutation({
      query: () => {
        return {
          url: "insurer/logout",
          method: "POST",
          body: null,
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const { useLogoutMutation, useGenerateOtpMutation, useVerifyOtpMutation } = AuthApi;
