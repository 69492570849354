import { FormLabel, Switch } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useGenerateOtpMutation } from "app/features/api/AuthApi";
import { EmailValidationSchema } from "Validation";
import { Toast } from "components/Toaster";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useState } from "react";

const EnterEmail = () => {
  localStorage.removeItem("timer");
  localStorage.removeItem("user");
  const navigate = useNavigate();
  const [remember, setRemembe] = useState(false);
  const [generateOtp] = useGenerateOtpMutation();
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: EmailValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        email: values.email,
        otp_type: "LOGIN",
      };
      const { data, error } = await generateOtp(body);
      if (data) {
        navigate("/verify-otp", { state: { body, data } });
        localStorage.removeItem("timer");
        localStorage.setItem("timer", JSON.stringify({ minute: 4, seconds: 59 }));
      }
      if (error) {
        Toast({ type: "error", message: error.data.status.message });
      }
    },
  });
  const handleSetRememberMe = () => {
    setRemembe(!remember);
    localStorage.setItem("isRemember", !remember);
  };
  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Login
      </SuiTypography>
      <SuiTypography component="p" textColor="text2" variant="formSubHeading">
        Filll up below details to login
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <FormLabel htmlFor="email">Email Address</FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off" }}
            type="email"
            placeholder="Enter Email Address"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.email} error={formik.errors.email} />
        </SuiBox>
      </SuiBox>
      <SuiBox my={2}>
        <Switch checked={remember} onChange={handleSetRememberMe} />
        <SuiTypography
          variant="button"
          fontWeight="regular"
          onClick={handleSetRememberMe}
          customClass="cursor-pointer user-select-none"
        >
          &nbsp;&nbsp;Remember Me
        </SuiTypography>
      </SuiBox>
      <SuiBox my={2}>
        <SuiButton
          tabIndex={2}
          type="submit"
          variant="contained"
          buttonColor="info"
          disabled={formik.isSubmitting || !formik.dirty}
          fullWidth
          onClick={formik.handleSubmit}
        >
          Send OTP
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default EnterEmail;
