import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  checkbox_wrap: {
    backgroundColor: "transparent",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    color: "#303841",
    cursor: "pointer",
    "&:last-of-type": { marginBottom: "0 !important" },
    "&.disabled": {
      pointerEvent: "none",
      opacity: 0.7,
      cursor: "default",
    },
  },
  autocompleteInput: {
    "&.active input": {
      "&::placeholder": { opacity: 1 },
      "&:-ms-input-placeholder": { opacity: 1 },
      "&::-ms-input-placeholder": { opacity: 1 },
    },
  },
}));
const SuiAutocomplete = ({
  id,
  multiple,
  value,
  loading,
  labelKey,
  valueKey,
  placeholder,
  onChange,
  disabled,
  noOptionsText = "No Options",
  options = [],
}) => {
  const classes = useStyles();
  const values = valueKey || multiple ? options.map((o) => o[valueKey || id]) : options;
  const [isAll, setIsAll] = React.useState(false);
  return (
    <Autocomplete
      id={id}
      key={id}
      value={value}
      disabled={disabled}
      onChange={(event, newValue) => {
        const all = newValue?.includes("all");
        if (all) {
          setIsAll(true);
          onChange(["all"]);
        } else {
          setIsAll(false);
          onChange(newValue);
        }
      }}
      noOptionsText={noOptionsText}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      fullWidth
      renderOption={(props, option, { selected }) => {
        const optionsItems = multiple ? (
          <li
            key={option}
            {...props}
            className={`${classes.checkbox_wrap} ${option !== "all" && isAll ? "disabled" : ""}`}
          >
            <Checkbox
              disabled={option === "all" ? false : isAll}
              sx={{ mr: 1, flexShrink: 0 }}
              checked={isAll ? true : selected}
            />
            <label
              style={{
                fontSize: "0.9rem",
                cursor: option !== "all" && isAll ? "default" : "pointer",
                lineHeight: 1,
              }}
            >
              {props.key}
            </label>
          </li>
        ) : (
          <li key={option} {...props}>
            {props.key}
          </li>
        );
        return optionsItems;
      }}
      disableClearable={!!multiple}
      renderTags={(value) => {
        const val = labelKey ? value[labelKey] : value;
        return multiple ? null : val;
      }}
      getOptionLabel={(option) => {
        const op = options.find((o) => o[valueKey || id] === option);
        const opVal = valueKey || multiple ? op?.[labelKey] : option;
        return opVal;
      }}
      options={values}
      loading={loading}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "0.125rem solid #D3E0E5",
          bgcolor: disabled ? "#E5EBED !important" : "#FFF",
          "&> .MuiOutlinedInput-input": {
            border: 0,
            "&+ .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={`${classes.autocompleteInput} ${value?.length > 0 ? "active" : ""}`}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="info" size={18} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default SuiAutocomplete;
