import { useState, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiOTPInput from "components/SuiOTPInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useGenerateOtpMutation, useVerifyOtpMutation } from "app/features/api/AuthApi";
import { setCredentials } from "app/features/AuthSlice";
import { useDispatch } from "react-redux";
import { Toast } from "components/Toaster";
import { setLocalStorageData } from "hooks/useLocalStorage";
const useStyles = makeStyles(() => ({
  lineHight: {
    lineHeight: "0.75rem !important",
  },
  padding: {
    paddingTop: "0",
  },
  icon: {
    fontSize: "0.85rem !important",
    marginTop: "2px",
  },
}));

const EnterOTP = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [error, setError] = useState(undefined);
  const [verifyOtp] = useVerifyOtpMutation();
  const [generateOtp] = useGenerateOtpMutation();
  const [btnTxt, setBtnTxt] = useState({ continue: "Continue", resend: "Resend" });
  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state]);
  const otpHandler = async (enterOtp) => {
    const enteredOTP = enterOtp.join("");
    if (enteredOTP.length < 6) {
      setError("Please enter valid otp");
    } else {
      const body = {
        email: state?.body.email,
        otp_type: state?.body.otp_type,
        otp: enteredOTP,
      };
      setBtnTxt({ ...btnTxt, continue: "Verifing OTP..." });
      const data = await verifyOtp(body);
      if (data?.data?.status?.code === 200) {
        await setLocalStorageData("token", data.data.data.access_token);
        await setLocalStorageData("refresh_token", data.data.data.refresh_token);
        await setLocalStorageData("tokenExpiryTime", data.data.data.access_token_expiry);
        await setLocalStorageData("loggedInTime", new Date());
        localStorage.removeItem("timer");
        dispatch(setCredentials({ token: data.data.data.access_token, isAuthenticated: true }));
        setBtnTxt({ ...btnTxt, continue: "Continue" });
        navigate("/dashboard");
      }
      if (data.error) {
        setBtnTxt({ ...btnTxt, continue: "Continue" });
        setError(data.error.data.status.message);
        return;
      }
    }
  };

  const reSendOTPHandler = async () => {
    const { data, error } = await generateOtp(state?.body);
    setBtnTxt({ ...btnTxt, resend: "Sending OTP..." });
    if (data) {
      localStorage.removeItem("timer");
      setBtnTxt({ ...btnTxt, resend: "Resend" });
      localStorage.setItem("timer", JSON.stringify({ minute: 4, seconds: 59 }));
    }
    if (error) {
      setBtnTxt({ ...btnTxt, resend: "Resend" });
      Toast({ type: "error", message: error.data.status.message });
    }
  };
  const emailAdd = `${state?.body.email.slice(0, 2).padEnd(6, "*")}${state?.body.email.slice(6)}`;
  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Verification Required
      </SuiTypography>
      <Grid
        className={classes.lineHight}
        columnSpacing={1}
        container
        rowSpacing={1}
        pt={0.5}
        pb={2.5}
        textAlign="left"
      >
        <Grid className={classes.padding} item>
          <SuiTypography variant="formSubHeading" textColor="text2" fontWeight="regular">
            We have sent you an OTP on
          </SuiTypography>
        </Grid>
        <Grid className={classes.padding} item xs="auto">
          <SuiTypography variant="formSubHeading" textColor="text2" fontWeight="regular">
            {emailAdd}
          </SuiTypography>
        </Grid>
      </Grid>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <SuiOTPInput
            initialResandTime={{ minute: 4, seconds: 59 }}
            buttonVariant={{ continue: "contained", reSend: "contained" }}
            buttonColor={{ continue: "info", reSend: "secondary" }}
            buttonTxt={{
              continue: btnTxt.continue,
              reSend: btnTxt.resend,
            }}
            btnFns={{ getOTPFn: otpHandler, reSendOTPFn: reSendOTPHandler }}
            error={{ setErrorFn: setError, msg: error }}
          />
        </SuiBox>
      </SuiBox>
    </>
  );
};
export default EnterOTP;
