import React from "react";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import colors from "assets/theme/base/colors";

const SuiDropDownList = ({ data, select, disabled, readOnly = false, isLoading }) => {
  return (
    <Select
      readOnly={readOnly}
      disabled={disabled}
      name={select.name}
      value={select.val}
      IconComponent={({ className }) => {
        const icon = isLoading ? (
          <span style={{ position: "absolute", right: 6, top: 7 }}>
            <CircularProgress size={30} sx={{ color: colors.info.main }} />
          </span>
        ) : (
          <ArrowDropDownIcon className={className} sx={{ fontSize: 12, color: "#6E828A" }} />
        );
        return icon;
      }}
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      sx={{
        "&> .MuiSelect-select": {
          color: select.val !== "" ? "inherit" : "#AAAEB1 !important",
        },
      }}
      onChange={(e) => {
        select.fn(e);
      }}
    >
      <MenuItem value="">{select.label}</MenuItem>
      {data &&
        data.map((item) => {
          return (
            <MenuItem
              key={item[select.key] || item}
              value={select.valueKey ? item[select.valueKey] : item}
              disabled={select.enableId && (select.enableId === item ? false : true)}
            >
              {item[select.labelName] || item}
            </MenuItem>
          );
        })}
    </Select>
  );
};

SuiDropDownList.propTypes = {
  data: PropTypes.any,
  select: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    val: PropTypes.any,
    fn: PropTypes.func,
  }),
  company: PropTypes.bool,
};

export default SuiDropDownList;
