import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import PageLayout from "Container/LayoutContainers/PageLayout";
import styles from "layouts/authentication/CoverLayout/styles";
import Logo from "assets/images/PolEasy_Full.png";

function CoverLayout({ image, children }) {
  const classes = styles({ image });

  return (
    <PageLayout customClass={classes.pageLayout} background="white">
      <Grid container justifyContent="center" alignItems="center" className={classes.coverLayout}>
        <Grid item xs={12} sm={8} lg={6}>
          <SuiBox className={classes.coverLayout_formBox}>
            <SuiBox className={classes.coverLayout_item}>
              <SuiBox p={0}>
                <SuiBox mb={1}>
                  <SuiBox
                    component="img"
                    src={Logo}
                    alt="PolEasy Logo"
                    customClass={classes.logo}
                  />
                </SuiBox>
              </SuiBox>
              <SuiBox component="form" autoComplete="off" pt={1} pb={2} px={0}>
                {children}
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} md={10} lg={12} xl={6} className={classes.imageBox}>
          <SuiBox customClass={classes.coverLayout_imageBox}>
            <SuiBox customClass={classes.coverLayout_image} />
          </SuiBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
};

CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
