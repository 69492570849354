import { generateData } from "api/helper";

export default (xTitle, data) => {
  const dates = data?.fetched_policy?.graph
    ? generateData(data?.fetched_policy?.graph, "date")
    : [new Date()];
  return {
    xTitle,
    labels: dates,
    datasets: [
      {
        label: "Policies fetched by customers",
        color: "success",
        data: data?.fetched_policy?.graph
          ? generateData(data?.fetched_policy?.graph, "count")
          : [0],
        borderWidth: 1.5,
        bgcolor: "transparent",
      },
      {
        label: "Service requests raised by customer",
        color: "info",
        data: data?.sr_raised?.graph ? generateData(data?.sr_raised?.graph, "count") : [0],
        borderWidth: 1.5,
        bgcolor: "transparent",
      },
    ],
  };
};
