import { makeStyles } from "@mui/styles";

export default makeStyles(
  ({ palette, transitions, breakpoints, typography, borders, functions }) => {
    const { transparent } = palette;
    const { fontWeightRegular, fontWeightMedium } = typography;
    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
      collapse_item: {
        background: transparent.main,
        display: "flex",
        alignItems: "center",
        borderRadius: borderRadius.md,
        backgroundColor: ({ active }) => (active ? "#E6EDF0" : transparent.main),
        width: "100%",
        margin: "6px 0 !important",
        padding: "10px 8px !important",
        paddingLeft: "20px !important",
        userSelect: "none",
        whiteSpace: "nowrap",
        boxShadow: "none",
      },
      collapse_iconBox: {
        background: transparent.main,
        minWidth: "auto",
      },

      collapse_text: {
        marginLeft: pxToRem(10),

        [breakpoints.up("xl")]: {
          opacity: ({ miniSidenav, transparentSidenav }) =>
            miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
          maxWidth: ({ miniSidenav, transparentSidenav }) =>
            miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
          marginLeft: ({ miniSidenav, transparentSidenav }) =>
            miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
          transition: transitions.create(["opacity", "margin"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },

        "& span": {
          fontWeight: ({ active }) => (active ? fontWeightMedium : fontWeightRegular),
          fontSize: 13,
          lineHeight: 0,
          color: "#303841",
        },
      },
    };
  }
);
