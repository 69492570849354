import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import CommonSpinner from "components/CommonSpinner";
import { getNewProperties, formatDate } from "api/helper";
import SuiDatePicker from "components/SuiDatePicker";
import { FormLabel } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import SuiButton from "components/SuiButton";
import colors from "assets/theme/base/colors";

import SuiBox from "components/SuiBox";
import GradientLineChart from "Container/Charts/LineCharts/GradientLineChart";
import DefaultDoughnutChart from "Container/Charts/DoughnutCharts/DefaultDoughnutChart";

import agentStatisticsData from "layouts/dashboard/data/agentStatisticsData";
import sellersOnboardedData from "layouts/dashboard/data/sellersOnboardedData";
import leadGenerationDoughnutChartData from "layouts/dashboard/data/leadGenerationDoughnutChartData";
import SuiDropDownList from "components/SuiDropDownList";
import SuiTypography from "components/SuiTypography";
import withGetContainer from "Container/withGetContainer";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import SuiAutocomplete from "components/SuiAutocomplete";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { DateValidateSchema } from "Validation";

function Dashboard() {
  const { info } = colors;
  const [search, setSearch] = useState();
  const formik = useFormik({
    initialValues: {
      start_date: null,
      end_date: null,
      duration: "DAILY",
      sr_type: "",
      state_ids: [],
      city_ids: [],
      branch_ids: [],
    },
    validationSchema: DateValidateSchema,
    onSubmit: (values) => {
      const search = getNewProperties(formik.initialValues, values);
      const searchParams = new URLSearchParams();
      for (let key in search) {
        if (key === "state_ids" || key === "city_ids" || key === "branch_ids") {
          search[key].map((s) => searchParams.append(key, s));
          continue;
        }
        searchParams.append(key, search[key]);
      }
      setSearch(searchParams);
    },
  });
  const { data, isLoading, isFetching } = useHandleGetApiQuery(
    {
      url: "insurer/dashboard",
      params: search ? search.toString() : {},
    },
    search
  );
  const dashboardData = data?.data;
  const {
    data: Filters,
    isFetching: fetching,
    isLoading: isStatesLoading,
  } = useHandleGetApiQuery({
    url: "insurer/dashboard/filters",
  });
  const extractValues = React.useCallback((obj) => {
    return Object.values(obj);
  }, []);

  const valueArray = React.useCallback((object, key) => {
    if (object) {
      const newObject = { ...object };

      const values = extractValues(newObject).map((item) => item[key]);
      return values;
    }
    return [];
  }, []);

  const handleStartDate = React.useCallback((e) => {
    if (e) {
      formik.setFieldValue("start_date", formatDate(new Date(e)));
      formik.setFieldValue("end_date", formatDate(new Date()));
    } else {
      formik.setErrors({});
      formik.setFieldValue("start_date", null);
      formik.setFieldValue("end_date", null);
    }
  }, []);
  const handleEndDate = React.useCallback((e) => {
    const toDate = new Date(e);
    if (e) {
      formik.setFieldValue("end_date", formatDate(toDate));
    } else {
      formik.setFieldValue("end_date", null);
    }
  }, []);

  const handleResetForm = React.useCallback(() => {
    formik.resetForm();
    setSearch();
  }, []);
  const {
    data: cities,
    isLoading: isCitiesLoading,
    isFetching: fetchingCities,
  } = useHandleGetApiQuery(
    {
      url: "insurer/dashboard/filters",
      params: new URLSearchParams(formik.values.state_ids.map((s) => ["state", s])).toString(),
    },
    {
      skip: formik.values.state_ids ? false : true,
      refetchOnMountOrArgChange: true,
    },
    formik.values.state_ids
  );

  const {
    data: branches,
    isLoading: isBranchesLoading,
    isFetching: fetchingBranches,
  } = useHandleGetApiQuery(
    {
      url: "insurer/dashboard/filters",
      params: new URLSearchParams([
        ...formik.values.state_ids.map((s) => ["state", s]),
        ...formik.values.city_ids.map((s) => ["city", s]),
      ]).toString(),
    },
    {
      skip: formik.values.state_ids || formik.values.city_ids ? false : true,
      refetchOnMountOrArgChange: true,
    },
    formik.values.city_ids,
    formik.values.state_ids
  );

  function dateRangeLabel(str) {
    const isNotNum = str?.split("-").every((i) => isNaN(+i));
    if (isNotNum) {
      return str;
    } else {
      const result = [str?.slice(0, 10), str?.slice(11)];
      const startDate = moment(new Date(result[0])).format("Do MMMM, YYYY");
      const endDate = moment(new Date(result[1])).format("Do MMMM, YYYY");
      return `${startDate} to ${endDate}`;
    }
  }
  const agent_statistics_label = dateRangeLabel(dashboardData?.agent_statistics.label);
  const agents_data_label = dateRangeLabel(dashboardData?.agents_data.label);
  if (isLoading || fetching) {
    return (
      <SuiBox
        height="calc(100vh - 150px)"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CommonSpinner size="100px" color={info.main} />
      </SuiBox>
    );
  }

  const btnDisabled =
    !search?.get("duration") || formik.dirty
      ? !formik.dirty
      : formik.values.duration === search?.get("duration");

  return (
    <SuiBox py={3} customClass={isFetching ? "loading" : ""}>
      <SuiBox mb={3}>
        <Grid container spacing={1.5} alignItems="flex-start">
          <Grid item xs={12} lg={7} xxl={6}>
            <FormLabel>Date Range</FormLabel>
            <Grid
              container
              columnSpacing={1}
              rowSpacing={2}
              flexWrap={{ xs: "wrap", md: "nowrap" }}
              pt={2}
            >
              <Grid item xs={12} md={5} sx={{ pt: "0 !important" }}>
                <SuiDatePicker
                  input={{
                    id: "start_date",
                    lable: "",
                    placeholder: "From",
                    minDate: new Date("01-01-2023"),
                    value: formik.values.start_date,
                    fn: handleStartDate,
                  }}
                />
                <ErrorMessageComponent
                  touched={formik.touched.start_date}
                  error={formik.errors.start_date}
                />
              </Grid>
              <Grid item xs={12} md={5} sx={{ pt: { xs: 1, md: "0 !important" } }}>
                <SuiDatePicker
                  input={{
                    id: "end_date",
                    lable: "",
                    placeholder: "To",
                    disabled: !formik.values.start_date ? true : false,
                    minDate: new Date(formik.values.start_date),
                    value: formik.values.end_date,
                    fn: handleEndDate,
                  }}
                />
                <ErrorMessageComponent
                  touched={formik.touched.end_date}
                  error={formik.errors.end_date}
                />
              </Grid>
              <Grid item xs={12} md={4} sx={{ pt: { xs: 1, md: "0 !important" } }}>
                <SuiDropDownList
                  data={[
                    { label: "Monthly", id: "MONTHLY" },
                    { label: "Daily", id: "DAILY" },
                  ]}
                  select={{
                    label: "Range",
                    hideLabel: true,
                    name: "duration",
                    key: "label",
                    labelName: "label",
                    valueKey: "id",
                    val: formik.values.duration,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={5} xxl={4}>
            <FormLabel>Area</FormLabel>
            <Grid
              container
              columnSpacing={1}
              rowSpacing={2}
              flexWrap={{ xs: "wrap", md: "nowrap" }}
              pt={2}
            >
              <Grid item xs={12} md={6} sx={{ pt: "0 !important" }}>
                <SuiAutocomplete
                  id="state_id"
                  loading={isStatesLoading && fetching}
                  options={Filters?.data.states}
                  labelKey="name"
                  placeholder={
                    formik.values.state_ids.length > 0
                      ? `${formik.values.state_ids.length} states selected`
                      : "Select State"
                  }
                  noOptionsText="No State Found."
                  multiple
                  value={formik.values.state_ids}
                  onChange={(val) => {
                    formik.setFieldValue("state_ids", val);
                    formik.setFieldValue("city_ids", []);
                    formik.setFieldValue("branch_ids", []);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ pt: { xs: 1, md: "0 !important" } }}>
                <SuiAutocomplete
                  id="city_id"
                  loading={isCitiesLoading && fetchingCities}
                  options={cities?.data.cities}
                  labelKey="name"
                  placeholder={
                    formik.values.city_ids.length > 0
                      ? `${formik.values.city_ids.length} cities selected`
                      : "Select City"
                  }
                  noOptionsText="No City Found."
                  multiple
                  value={formik.values.city_ids}
                  onChange={(val) => {
                    formik.setFieldValue("city_ids", val);
                    formik.setFieldValue("branch_ids", []);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xxl={2}>
            <FormLabel>Branch</FormLabel>
            <SuiAutocomplete
              id="branch_id"
              loading={isBranchesLoading && fetchingBranches}
              options={branches?.data.branches}
              labelKey="name"
              placeholder={
                formik.values.branch_ids.length > 0
                  ? `${formik.values.branch_ids.length} branch selected`
                  : "Branch City"
              }
              noOptionsText="No Branch Found."
              multiple
              value={formik.values.branch_ids}
              onChange={(val) => formik.setFieldValue("branch_ids", val)}
            />
          </Grid>
          <Grid item mt="auto" alignSelf="flex-end">
            <SuiButton
              onClick={formik.handleSubmit}
              disabled={btnDisabled}
              variant="contained"
              buttonColor="info"
              sx={{ px: "2rem", py: "0.4rem", fontSize: "14px" }}
            >
              Apply
            </SuiButton>
          </Grid>
          <Grid item alignSelf="flex-end">
            <SuiButton
              type="reset"
              onClick={handleResetForm}
              variant="outlined"
              buttonColor="dark"
              disabled={btnDisabled}
              sx={{ px: "2rem", py: "0.4rem", fontSize: "14px" }}
            >
              Reset
            </SuiButton>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <GradientLineChart
          height={{ xs: "25.5rem", xl: "20.5rem" }}
          chart={agentStatisticsData(
            dashboardData?.agent_statistics.label ? agent_statistics_label : "",
            dashboardData?.agent_statistics
          )}
        />
      </SuiBox>
      <SuiBox mb={3} pt={2}>
        <SuiTypography textColor="text2" fontWeight="bold" fontSize={{ xs: 14, md: 16, xl: 18 }}>
          SELLERS (AGENTS) DATA
        </SuiTypography>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <DefaultDoughnutChart
              totalText="SRs"
              height={{ xs: "18rem", xl: "19.1rem" }}
              title="TYPES OF SR’S RAISED & COUNT (Through Sellers)"
              loading={isStatesLoading && fetching}
              dropdownData={Filters?.data.sr_types}
              srValue={formik.values.sr_type}
              setValueFn={(e) => {
                formik.setFieldValue("sr_type", e.target.value);
                formik.submitForm();
              }}
              chart={leadGenerationDoughnutChartData(
                "Service Request Count",
                valueArray(dashboardData?.service_request_raised, "label"),
                valueArray(dashboardData?.service_request_raised, "count"),
                valueArray(dashboardData?.service_request_raised, "color_code")
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <GradientLineChart
              // title="NO. OF SELLERS ONBOARDED"
              height={{ xs: "25.5rem", xl: "20.5rem" }}
              // showTotalCount
              chart={sellersOnboardedData(
                dashboardData?.agents_data.label ? agents_data_label : "",
                dashboardData?.agents_data
              )}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}
export default withGetContainer({ WrappedComponent: Dashboard });
