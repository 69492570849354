import { generateData } from "api/helper";

export default (xTitle, data) => {
  const dates = data?.sellers_onboarded?.graph
    ? generateData(data?.sellers_onboarded?.graph, "date")
    : [new Date()];
  return {
    xTitle,
    labels: dates,
    datasets: [
      {
        label: "Customer on boarded by seller",
        color: "warning",
        data: data?.customer_onboarded?.graph
          ? generateData(data?.customer_onboarded?.graph, "count")
          : [0],
        borderWidth: 1.5,
        bgcolor: "transparent",
      },
      {
        label: "No. of sellers on board",
        color: "info",
        data: data?.sellers_onboarded?.graph
          ? generateData(data?.sellers_onboarded?.graph, "count")
          : [0],
        borderWidth: 1,
        bgcolor: "transparent",
      },
    ],
  };
};
