import React from "react";

import { useToaster, toast } from "react-hot-toast";

const ErrorComponent = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, updateHeight } = handlers;
  return (
    <div
      style={{
        position: "fixed",
        top: 10,
        right: 20,
        margin: 10,
        zIndex: 99999999,
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toastItem) => {
        const color = toastItem.type === "error" ? "#d32f2f" : "#1349a7";
        const ref = (el) => {
          if (el && !toastItem.height) {
            const { height } = el.getBoundingClientRect();
            updateHeight(toastItem.id, height);
          }
        };
        return (
          <div
            role="alert"
            aria-label="notification"
            key={toastItem.id}
            ref={ref}
            style={{
              background: color,
              padding: ".7rem",
              borderRadius: ".3rem",
              marginTop: ".2rem",
              boxShadow: "1px 3px 1px #9E9E9E",
              color: "#fff",
            }}
          >
            {toastItem.message}
          </div>
        );
      })}
    </div>
  );
};

export default ErrorComponent;

export const Toast = ({ type, message }) => {
  if (type === "error") {
    return toast.error(message, { duration: 500 });
  }
  return toast.success(message, { duration: 500 });
};
