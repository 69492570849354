import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const { error } = colors;

export default {
  styleOverrides: {
    root: {
      fontSize: pxToRem(13),
      fontWeight: "500",
    },
    asterisk: {
      color: error.focus,
    },
  },
};
