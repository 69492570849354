import { baseAPIService } from ".";

const CommonPostApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    handlePost: build.mutation({
      query: ({ url, data }) => {
        return {
          url: url,
          method: "POST",
          body: data ? data : null,
        };
      },
      invalidatesTags: ["COMMON_GET"],
    }),
  }),
  overrideExisting: true,
});
export const { useHandlePostMutation } = CommonPostApi;
