import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import typography from "assets/theme/base/typography";
import styles from "Container/Footer/styles";
import { Link as Nav } from "react-router-dom";

const Footer = ({ company, links }) => {
  const { size } = typography;
  const classes = styles();

  const renderLinks = () =>
    links.map((link) => (
      <SuiBox key={link.name} component="li" px={2}>
        <Link component={Nav} href={link.href}>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            {link.name}
          </SuiTypography>
        </Link>
      </SuiBox>
    ));

  return (
    <SuiBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <SuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy;
        <SuiTypography variant="button" fontWeight="medium">
          &nbsp;{company}&nbsp;
        </SuiTypography>
        2022-{new Date().getFullYear()}
      </SuiBox>
      <SuiBox component="ul" customClass={classes.footer_list}>
        {renderLinks()}
      </SuiBox>
    </SuiBox>
  );
};

Footer.defaultProps = {
  company: "PolEasy",
  links: [
    { href: "", name: "Terms of Use" },
    { href: "", name: "Support" },
  ],
};

Footer.propTypes = {
  company: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
