import { useHandleUpdateOrDeleteMutation } from "app/features/api/CommonChangeApi";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { useHandlePostMutation } from "app/features/api/CommonPostApi";
import { Toast } from "components/Toaster";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const withPostContainer = (WrappedComponent, url, isProfile) => {
  const newComponent = () => {
    const navigate = useNavigate();
    const [handlePost] = useHandlePostMutation();
    const { permissions } = useSelector((store) => store.auth);
    const [handleUpdateOrDelete] = useHandleUpdateOrDeleteMutation();
    const { id } = useParams();
    const { data } = useHandleGetApiQuery(
      { url: `${url}${id}` },
      { skip: id && url ? false : true, refetchOnMountOrArgChange: true },
      id
    );
    useEffect(() => {
      if (!permissions?.create && isProfile === false) {
        navigate(-1);
      } else if (!permissions?.update && isProfile === false) {
        navigate(-1);
      }
    }, [permissions]);
    const handlePostRequest = async ({ url, data, isUpdate, redirect, setSubmitting }) => {
      const response = isUpdate
        ? await handleUpdateOrDelete({
            url: `${url}`,
            data,
            update: true,
          })
        : await handlePost({ url, data });
      if (response?.error) {
        if (response?.error?.originalStatus >= 500) {
          Toast({ type: "error", message: response?.error?.status });
        } else {
          if (response?.error?.data.status) {
            Toast({ type: "error", message: response?.error?.data.status.message });
          } else {
            Toast({ type: "error", message: "Something went wrong!." });
          }
        }
      }
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (response?.data?.status.code === 201 || response?.data?.status.code === 200) {
        Toast({ message: response?.data.status.message });
        if (redirect) {
          navigate(redirect);
        }
      }
    };
    return <WrappedComponent handlePostRequest={handlePostRequest} data={data} id={id} />;
  };

  return newComponent;
};

export default withPostContainer;
