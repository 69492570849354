import { Link } from "react-router-dom";

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import SuiBox from "components/SuiBox";

import styles from "Container/Sidenav/styles/subLink";

function SubLink({ to, name, active }) {
  const controller = useSelector((store) => store.uiControl);
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;
  const classes = styles({
    active,
    miniSidenav,
    transparentSidenav,
    sidenavColor,
  });

  return (
    <SuiBox component={Link} to={to} customClass={classes.collapse_item}>
      <ListItemIcon className={classes.collapse_iconBox}>
        {active ? <CircleRoundedIcon sx={{ fill: "#ED952F" }} /> : <CircleOutlinedIcon />}
      </ListItemIcon>
      <ListItemText primary={name} classes={{ root: classes.collapse_text }} />
    </SuiBox>
  );
}

SubLink.defaultProps = {
  active: false,
};

SubLink.propTypes = {
  icon: PropTypes.any,
  to: PropTypes.any,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SubLink;
