import React from "react";
import SuiBox from "components/SuiBox";
import { Checkbox } from "@mui/material";
import SuiTypography from "components/SuiTypography";

const BoxLegends = ({ id, color, label, onLegendFn }) => {
  return (
    <SuiBox
      sx={{
        userSelect: "none",
        "& > .MuiTypography-root": {
          textDecoration: "line-through",
          opacity: 0.7,
        },
        "& > .MuiCheckbox-root.Mui-checked + .MuiTypography-root": {
          textDecoration: "none",
          opacity: 1,
        },
      }}
    >
      <SuiBox mb={0.5} backgroundColor={color} width={20} height={8} borderRadius="lg" />
      <Checkbox
        size="small"
        id={`legend_${id}`}
        name={`legend_${id}`}
        defaultChecked
        sx={{ display: "none" }}
        onChange={onLegendFn}
      />
      <SuiTypography
        sx={{ cursor: "pointer" }}
        component="label"
        htmlFor={`legend_${id}`}
        fontSize={{ xs: 12, lg: 14 }}
        fontWeight="medium"
        textColor="text2"
      >
        {label}
      </SuiTypography>
    </SuiBox>
  );
};

export default BoxLegends;
