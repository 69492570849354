import { lazy } from "react";
import RootLayout from "layouts/RootLayout";
import RootOutlet from "layouts/RootLayout/RootOutlet";

import Dashboard from "layouts/dashboard";

const RolesTable = lazy(() => import("layouts/roles/RolesTable"));
const CommonRolesModules = lazy(() => import("layouts/roles/CommonRolesModules"));

const SubUserTable = lazy(() => import("layouts/sub-user/SubUserTable"));
const CommonSubUserModules = lazy(() => import("layouts/sub-user/CommonSubUserModules"));

const SellerReport = lazy(() => import("layouts/reports/SellerReport"));

const AuditLogs = lazy(() => import("layouts/audit-logs-report/AuditLogs"));

const DownloadReport = lazy(() => import("layouts/downloads"));

import LogIn from "layouts/authentication/log-in";
import EnterEmail from "layouts/authentication/log-in/Forms/EnterEmail";
import EnterOTP from "layouts/authentication/log-in/Forms/EnterOTP";
import LogOut from "layouts/authentication/log-out";

import NotFound from "layouts/404";
import PoleaseLogin from "layouts/authentication/log-in/Forms/PoleasyLogin";
import LeadManagementTable from "layouts/lead-management/LeadManagementTable";
import LeadManagementView from "layouts/lead-management/LeadManagementView";

const routes = [
  {
    path: "",
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
        index: true,
      },
      {
        path: "/roles",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <RolesTable />,
          },
          {
            path: "add-role",
            element: <CommonRolesModules />,
          },
          {
            path: "edit-role/:id",
            element: <CommonRolesModules />,
          },
        ],
      },
      {
        path: "/sub-users",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <SubUserTable />,
          },
          {
            path: "add-sub-users",
            element: <CommonSubUserModules />,
          },
          {
            path: "edit-sub-user/:id",
            element: <CommonSubUserModules />,
          },
        ],
      },
      {
        path: "/lead-generation-reports",
        element: <RootOutlet />,
        protected: true,
        children: [
          {
            path: "",
            index: true,
            element: <LeadManagementTable />,
          },
          {
            path: ":id",
            element: <LeadManagementView />,
          },
        ],
      },
      {
        path: "/seller-reports",
        element: <SellerReport />,
        protected: true,
      },
      {
        path: "/audit-logs",
        element: <AuditLogs />,
        protected: true,
      },
      {
        path: "/downloads",
        element: <DownloadReport />,
        protected: true,
      },
      {
        path: "log-out",
        element: <LogOut />,
      },
    ],
    protected: true,
  },
  {
    path: "/",
    element: <LogIn />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <EnterEmail />,
      },
      {
        element: <EnterOTP />,
        path: "verify-otp",
      },
      {
        path: "/poleasy-login",
        element: <PoleaseLogin />,
      },
    ],
  },
];

export default routes;
