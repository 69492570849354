import { useEffect, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import configs from "Container/Charts/DoughnutCharts/DefaultDoughnutChart/configs";
import BoxLegends from "Container/ChartLegend/BoxLegends";
import SuiDropDownList from "components/SuiDropDownList";

function DefaultDoughnutChart({
  title,
  height,
  chart,
  totalText,
  loading,
  dropdownData,
  srValue,
  setValueFn,
}) {
  const myChartRef = useRef();
  const { data, options } = configs(chart.labels, chart.datasets, chart.cutout);
  const [srTypes, setSRTypes] = useState([]);
  useEffect(() => {
    const sortingFn = (a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    };
    if (dropdownData) {
      const dropData = dropdownData?.slice().sort(sortingFn);
      setSRTypes(dropData);
    }
  }, [dropdownData]);
  const centerText = {
    id: "centerText",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0]?.x;
      const yCoor = chart.getDatasetMeta(0).data[0]?.y;
      const total = data.datasets[0].data.reduce((totalVal, num) => totalVal + num, 0);

      ctx.save();
      ctx.font = "500 15px Outfit";
      ctx.fillStyle = "#6E828A";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText("Total", xCoor, yCoor - 18);
      ctx.font = "600 16px Outfit";
      ctx.fillStyle = "#303841";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText(`${total} ${totalText}`, xCoor, yCoor);
    },
  };
  const toggleLegend = (e, index) => {
    const myChart = myChartRef.current;
    myChart.legend.chart.toggleDataVisibility(index);
    myChart.update();
  };
  const renderChart = (
    <SuiBox p={2}>
      {title ? (
        <Grid
          container
          pl={{ xs: 1, md: 0 }}
          alignItems="center"
          justifyContent="space-between"
          columnSpacing={{ xs: 0, md: 1.5 }}
          rowSpacing={1}
          mb={2}
        >
          <Grid item xs={12} md={6}>
            <SuiTypography variant="h6" fontSize={13} lineHeight={1.2}>
              {title}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} md={5}>
            <SuiDropDownList
              data={srTypes}
              select={{
                label: "Select SR Type",
                name: "sr_type",
                key: "key",
                valueKey: "key",
                labelName: "label",
                val: srValue,
                fn: setValueFn,
              }}
              isLoading={loading}
            />
          </Grid>
        </Grid>
      ) : null}
      <SuiBox minHeight={height} pt={2}>
        <Grid container spacing={2} alignItems="center" py={1}>
          <Grid item xs={12} sm={7} lg={12} xl={8} height="15rem">
            {useMemo(
              () => (
                <>
                  {!chart.labels.length ? (
                    <SuiTypography py={10} textAlign="center">
                      No data found.
                    </SuiTypography>
                  ) : (
                    <Doughnut
                      id={title}
                      ref={myChartRef}
                      data={data}
                      options={options}
                      plugins={[centerText]}
                    />
                  )}
                </>
              ),
              [chart, height]
            )}
          </Grid>
          <Grid item xs={12} sm={5} lg={12} xl={4}>
            <Grid container rowSpacing={1} columnSpacing={1.5} alignItems="flex-start">
              {chart.labels.map((label, index) => (
                <Grid item xs={6} sm={12} md={6} xl={12} key={index}>
                  <BoxLegends
                    id={`${title}_${index}`}
                    color={chart.datasets.backgroundColors[index]}
                    label={`${label} (${chart.datasets.data[index]})`}
                    onLegendFn={(e) => toggleLegend(e, index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );

  return title ? <Card>{renderChart}</Card> : renderChart;
}

DefaultDoughnutChart.defaultProps = {
  title: "",
  height: "100%",
};

DefaultDoughnutChart.propTypes = {
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default DefaultDoughnutChart;
