export default {
  styleOverrides: {
    root: {
      backgroundColor: "rgba(48, 56, 65, 0.31)",
      backdropFilter: "blur(5px)",
    },
    invisible: {
      backgroundColor: "transparent",
      backdropFilter: "none",
    },
  },
};
