import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setLocalStorageData } from "hooks/useLocalStorage";
import { getLocalStorageData } from "hooks/useLocalStorage";
import { Logout, setCredentials } from "../AuthSlice";
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    const remember = JSON.parse(localStorage.getItem("isRemember"));
    const token = await getLocalStorageData(remember ? "refresh_token" : "token");
    headers.set("Public-Key", "1d5ee304-5de4-4490-8cfa-4b23a2bb5058");
    headers.set("x-appversion", "web");
    headers.set("x-platform", "web");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const remember = JSON.parse(localStorage.getItem("isRemember"));
  const token = await getLocalStorageData(remember ? "refresh_token" : "token");
  if (result?.error?.status === 403) {
    const refreshResult = await baseQuery(
      {
        url: "insurer/token/refresh",
        method: "POST",
        body: {
          refresh_token: token,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult?.error) {
      api.dispatch(Logout());
    }
    if (refreshResult?.data.status.code === 200) {
      await setLocalStorageData("token", refreshResult?.data.data.access_token);
      await setLocalStorageData("tokenExpiryTime", refreshResult?.data.data.access_token_expiry);
      await setLocalStorageData("loggedInTime", new Date());
      await setLocalStorageData("refresh_token", refreshResult?.data.data.refresh_token);
      api.dispatch(
        setCredentials({ token: refreshResult?.data.data.token, isAuthenticated: true })
      );
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseAPIService = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["COMMON_GET"],
  endpoints: () => ({}),
});
