import moment from "moment";
import * as yup from "yup";
const alphaNumeric = /^[a-z\d\-_\s]+$/i;
const emailValidation = /(?=^.{3,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,10}$/i;

export const SearchSchema = yup.object({
  usr_name: yup.string("Please enter valid name."),
  usr_mobile_no: yup.number("Please enter valid mobile number."),
  usr_email: yup.string().matches(emailValidation, "Please enter valid email address."),
  page: yup.number("Please enter valid page number."),
});
export const EmailValidationSchema = yup.object({
  email: yup.string().matches(emailValidation, "Please enter valid email address."),
});

export const SubUserAddSchema = yup.object({
  name: yup
    .string()
    .required("Please enter your name.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  email: yup
    .string()
    .matches(emailValidation, "Please enter valid email address.")
    .required("You must enter your email address."),
  assigned_role: yup.string().required("Please select a role."),
  status: yup.string().required("Please select a status."),
});

export const RoleAddSchema = yup.object({
  role_name: yup
    .string()
    .required("Please enter your name.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  role_permissions: yup.array(),
  zonal_permissions: yup.object({
    is_national: yup.boolean(),
    state_ids: yup
      .array()
      .test(
        "oneOfRequired",
        "One of state_ids, city_ids, or branch_ids must be selected",
        (item, testContext) => {
          return (
            testContext.parent.is_national ||
            testContext.parent.state_ids.length > 0 ||
            testContext.parent.is_national ||
            testContext.parent.city_ids.length > 0 ||
            testContext.parent.is_national ||
            testContext.parent.branch_ids.length > 0
          );
        }
      ),
    city_ids: yup
      .array()
      .test(
        "oneOfRequired",
        "One of state_ids, city_ids, or branch_ids must be selected",
        (item, testContext) => {
          return (
            testContext.parent.is_national ||
            testContext.parent.state_ids.length > 0 ||
            testContext.parent.is_national ||
            testContext.parent.city_ids.length > 0 ||
            testContext.parent.is_national ||
            testContext.parent.branch_ids.length > 0
          );
        }
      ),
    branch_ids: yup
      .array()
      .test(
        "oneOfRequired",
        "One of state_ids, city_ids, or branch_ids must be selected",
        (item, testContext) => {
          return (
            testContext.parent.is_national ||
            testContext.parent.state_ids.length > 0 ||
            testContext.parent.is_national ||
            testContext.parent.city_ids.length > 0 ||
            testContext.parent.is_national ||
            testContext.parent.branch_ids.length > 0
          );
        }
      ),
  }),
});
export const DateValidateSchema = yup.object({
  start_date: yup
    .date()
    .max(new Date(), "Start date should not be greater than today.")
    .typeError("Please enter valid start date.")
    .nullable(),
  end_date: yup
    .date()
    .max(new Date(), "End date should not be greater than today.")
    .when("start_date", {
      is: (start_date) => moment.isDate(start_date),
      then: yup
        .date()
        .required("Please enter end date.")
        .typeError("Please enter valid end date.")
        .nullable(),
    })
    .test("lessthenSD", "End date should not be less than start date.", (value, testContext) => {
      return !moment(value).isBefore(testContext.parent.start_date);
    })
    .typeError("Please enter valid end date.")
    .nullable(),
});
